const THEMES = {
  edge: {
    logo: '/edge-logo-dark.png',
    color: '#002433',
    name: 'Edge'
  },
  admin: {
    logo: '/admin.png',
    color: '#657B84',
    name: 'Admin'
  },
  assembly: {
    logo: '/assembly.png',
    color: '#99E1FF',
    name: 'Assembly'
  },
};

export const getTheme = (app) => {
  if (Object.keys(THEMES).includes(app)) return THEMES[app];
  return THEMES['edge'];
}

const AppState = "CeterusAppState";

export {
  THEMES,
  AppState
};