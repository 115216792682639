const getUrl = () => {
  if (process.env.REACT_APP_LOCAL_ENV && process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_LOCAL_ENV === 'local' ? `http://localhost:${process.env.REACT_APP_API_PORT}/api/` : `https://${process.env.REACT_APP_LOCAL_ENV}-api-customer.ceterusinsight.com/api/`;
  } else {
    return 'https://dev4-api-customer.ceterusinsight.com/api/';
  }
};

export const API = getUrl();

export const oktaDomain = 'https://login.ceterus.com';