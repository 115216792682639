import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AppContext from '../../../../context';
import LoadingIndicator from '../../../LoadingIndicator';
import CeterusAuthService from '../../../../services/CeterusAuthService';
import { AppState } from '../../../../constants';

const subject = "Ceterus Edge Login Error";
const BREAK = "%0D%0A";
const linkStyle = {
  color: 'white'
}

// This page will verify with the Ceterus API and redirect to the desired page in the desired app
const Authenticated = () => {
  const { authState } = useOktaAuth();
  const { ceterusApp, setCeterusApp, path, setPath } = useContext(AppContext);

  const [edgeToken, setEdgeToken] = useState();
  const [authError, setAuthError] = useState();

  const [loading, setLoading] = useState(false);

  const [redirecting, setRedirecting] = useState();

  const [ceterusResponse, setCeterusResponse] = useState();

  useEffect(() => {
    if (!ceterusResponse || redirecting || !edgeToken) return;

    // Default application they can access
    let appUrl = ceterusResponse.app;

    // Check to see if the app they tried to open is on the list of approved apps
    if (ceterusApp) {
      let allowedToAccessApp = false;
      ceterusResponse.approvedApps.forEach((approvedApp) => {
        if (ceterusApp === approvedApp.app) {
          allowedToAccessApp = true;
          appUrl = approvedApp.url; // If they're allowed to access the app, grab the URL for it
        }
      });

      // If the user is trying to login to an app they don't can't access, wipe out the URL path they tried to visit before redirecting to their default allowed app
      if (!allowedToAccessApp) setPath("");
    }

    sessionStorage.setItem(AppState, null); // trash the temporary app state right before redirecting

    setRedirecting(true);

    const pathParam = path ? `&url=${encodeURIComponent(path)}` : "";
    window.location.assign(`${appUrl}?token=${edgeToken}${pathParam}`);
  }, [ceterusResponse, ceterusApp, path, setCeterusApp, setPath, edgeToken, redirecting])

  useEffect(() => {
    if (authState.isAuthenticated && !edgeToken && !authError && !loading) {
      setLoading(true);
      const oktaToken = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.value;
      CeterusAuthService.authorizeUser(oktaToken)
        .then((response) => {
          if (response.error) {
            setAuthError(response.data); // something broken in the api
            return;
          }

          setCeterusResponse(response); // we got back expected data
          setEdgeToken(response.token);
        })


      setLoading(false);
    }
  }, [authError, authState.isAuthenticated, edgeToken, loading])

  return !authError ?
    <LoadingIndicator />
    : (
      <>
        There was an error connecting to the Ceterus authentication service.<br />
        Please <a style={linkStyle} href="/logout">click here</a> to log out and try again, or <a style={linkStyle} href={`mailto:support@ceterus.com?subject=${subject}&body=${BREAK}${BREAK}${BREAK}${BREAK}Error Code: ${authError.status}${BREAK}Error Message: ${authError.statusText}`}>click here</a> to contact Ceterus support.
      </>
    );
}

export default Authenticated;