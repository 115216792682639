import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context';
import LoadingIndicator from '../../LoadingIndicator';
import { AppState } from '../../../constants';

// This page will verify with the Ceterus API and redirect to the desired page in the desired app
const Authenticated = ({ data }) => {
  const { ceterusApp, path, setPath } = useContext(AppContext);

  const [redirecting, setRedirecting] = useState();

  useEffect(() => {
    if (!data || redirecting) return;

    // Default application they can access
    let appUrl = data.app;

    // Check to see if the app they tried to open is on the list of approved apps
    if (ceterusApp) {
      let allowedToAccessApp = false;
      data.approvedApps.forEach((approvedApp) => {
        if (ceterusApp === approvedApp.app) {
          allowedToAccessApp = true;
          appUrl = approvedApp.url; // If they're allowed to access the app, grab the URL for it
        }
      });

      // If the user is trying to login to an app they don't can't access, wipe out the URL path they tried to visit before redirecting to their default allowed app
      if (!allowedToAccessApp) setPath("");
    }

    sessionStorage.setItem(AppState, null); // trash the temporary app state right before redirecting

    setRedirecting(true);

    const pathParam = path ? `&url=${encodeURIComponent(path)}` : "";
    window.location.assign(`${appUrl}?token=${data.token}${pathParam}`);
  }, [data, ceterusApp, path, setPath, redirecting])

  return <LoadingIndicator />;
}

export default Authenticated;