import fetch from 'isomorphic-fetch';

const defaultGetOptions = {
  method: 'GET'
};

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const asyncService = {
  get: (url = '', options = {}, authToken) => {
    // checkForValidToken();
    let token = authToken ? authToken : localStorage.getItem('token');
    const getOptions = {
      ...defaultGetOptions,
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      },
      ...options
    };

    return fetch(url, getOptions);
  }
};

export default asyncService;
