import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from '.';
import { AppState } from '../constants';

const Provider = ({ children }) => {
  const dataFromStorage = JSON.parse(sessionStorage.getItem(AppState)) || {};

  const [ceterusApp, setCeterusApp] = useState(dataFromStorage.ceterusApp);
  const [path, setPath] = useState(dataFromStorage.path);
  const [error, setError] = useState(dataFromStorage.error);

  // We'll cache this in sessionStorage because the Okta widget is very fond of redirecting and losing app state
  useEffect(() => {
    const state = {
      ceterusApp,
      path,
      error
    };

    sessionStorage.setItem(AppState, JSON.stringify(state))
  }, [ceterusApp, path, error])

  const context = {
    ceterusApp,
    setCeterusApp,
    path,
    setPath,
    error,
    setError
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.object
};

export default Provider;
