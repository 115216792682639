import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import LoadingIndicator from '../LoadingIndicator';

// This page will delete the tokens and other necessary information and return to the login page
const Logout = () => {
  const { authService } = useOktaAuth();
  const [started, setStarted] = useState(false);

  useEffect(() => {
    if (!started) {
      setStarted(true);
      authService.logout('/').catch(() => {
        window.location.assign("/");
        return false;
      }); // If the session times out on the Okta side it will throw a JS error when logging out. This prevents that from breaking the page. https://github.com/okta/okta-auth-js/issues/215
    }
  }, [started, authService])

  return (
    <LoadingIndicator />
  )
}

export default Logout;