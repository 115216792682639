import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import Provider from './context/Provider';
import Maintenance from './components/Maintenance';
import OktaLogin from './components/Login/Okta';
import CeterusLogin from './components/Login/Ceterus';
import Logout from './components/Logout';
import Authenticated from './components/Login/Okta/Authenticated';
import LoadingIndicator from './components/LoadingIndicator';
import { API, oktaDomain } from './api';
import { getParameter } from './services/parseURL';
import async from './services/async'
import { getTheme } from './constants';
import AppContext from './context';

const formatDate = (date) => {
  const obj = new Date(date);

  return `${obj.toLocaleDateString()} at ${obj.toLocaleTimeString()}`;
}

export const appConfig = {
  clientId: '0oa1115hmxAfaEwla357',
  issuer: `${oktaDomain}/oauth2/default`,
  redirectUri: `${window.location.protocol}//${window.location.host}/implicit/callback`,
  pkce: true,
  scopes: ['openid', 'profile', 'email']
};

// This parent component wraps the whole application and handles some app-wide state logic
const RoutingHandler = ({ children }) => {
  const { ceterusApp, setCeterusApp, path, setPath, error, setError } = useContext(AppContext);

  // The application in the ?app= parameter
  const appFromUrl = getParameter("app");
  useEffect(() => {
    if ((!ceterusApp || ceterusApp !== appFromUrl) && appFromUrl !== "") setCeterusApp(appFromUrl);
  });

  // The page url in the ?url= parameter
  const pathFromUrl = getParameter("url");
  useEffect(() => {
    if ((!path || path !== pathFromUrl) && pathFromUrl !== "") setPath(pathFromUrl);
  })

  // The error message in the ?error= parameter
  const errorFromUrl = getParameter("error");
  useEffect(() => {
    if ((!error || error !== errorFromUrl) && errorFromUrl !== "") setError(errorFromUrl);
  })

  const { name, color } = getTheme(ceterusApp);

  document.getElementsByTagName('body')[0].style.backgroundColor = color;
  document.title = `Ceterus ${name} Login`;

  return children;
};

// Main app
const AppWithRouter = () => {
  const [systemStatus, setSystemStatus] = useState(null);
  const [loading, setLoading] = useState();

  const { ceterusApp } = useContext(AppContext);

  useEffect(() => {
    setLoading(true)
    async.get(`${API}status`)
      .then((r) => r.json())
      .then((json) => setSystemStatus(json))
      .finally(() => setLoading(false))
  }, [])

  const getLoginType = () => {
    // we default to edge if no app name is provided, assuming a client came here
    if (systemStatus?.maintenance?.apps?.[ceterusApp ?? 'edge']) return Maintenance;
    if (loading) return LoadingIndicator;
    return systemStatus?.ceterusBasedLogin ? CeterusLogin : OktaLogin;
  }

  const history = useHistory();
  document.getElementsByTagName('body')[0].style.backgroundColor = "black";
  return (
    <RoutingHandler>
      <Security
        {...appConfig}
        onAuthRequired={() => history.push('/login')}
      >

        {systemStatus?.maintenance?.show && <div className='info panel'>We will be performing scheduled maintenance on {formatDate(systemStatus?.maintenance.date)}. Ceterus apps may be unavailable during the maintenance period.</div>}

        <SecureRoute path="/" exact component={Authenticated} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <Route path="/login" exact component={getLoginType()} />
        <Route path="/logout" exact component={Logout} />
      </Security>
    </RoutingHandler>
  )
};

const App = () => (
  <Router>
    <Provider>
      <AppWithRouter />
    </Provider>
  </Router>
);

export default App;