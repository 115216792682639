import React, { useContext } from 'react';
import { getTheme } from '../../constants';
import AppContext from '../../context';

const Maintenance = () => {
  const { ceterusApp } = useContext(AppContext);
  const { logo, name } = getTheme(ceterusApp)

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className='ceterusLoginPanel'>
        <img src={logo} alt="Logo" style={{ maxHeight: 40, maxWidth: 200 }} />
        <div style={{ fontWeight: 900, fontSize: 24 }}>We're currently working on improving {name} for you.</div>
        <div>We'll be back in a few hours.<br />Please check back soon.</div>
      </div>
    </div>
  )
}

export default Maintenance;