import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { oktaDomain } from '../../../api';
import { appConfig } from '../../../App';
import AppContext from '../../../context';
import { getTheme } from '../../../constants';

const LoginForm = () => {
  const { authService } = useOktaAuth();
  const { ceterusApp } = useContext(AppContext);

  useEffect(() => {
    const { clientId, issuer, redirectUri, scopes } = appConfig;
    const widget = new OktaSignIn({
      baseUrl: oktaDomain,
      clientId,
      redirectUri,
      colors: {
        brand: '#00b5ff'
      },
      helpLinks: {
        forgotPassword: 'https://edge.ceterus.com/resetPassword',
        custom: [
          {
            text: 'Contact Ceterus support',
            href: 'mailto:support@ceterus.com?subject=Login%20support%20request'
          }
        ]
      },
      logo: getTheme(ceterusApp).logo,
      i18n: {
        en: {
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.password.placeholder': 'Password'
        },
      },
      authParams: {
        issuer,
        scopes
      },
    });

    widget.renderEl(
      { el: '#sign-in-widget' },
      ({ tokens }) => {
        // Add tokens to storage
        const tokenManager = authService.getTokenManager();
        tokenManager.add('idToken', tokens.idToken);
        tokenManager.add('accessToken', tokens.accessToken);

        // Return to the original URL (if auth was initiated from a secure route), falls back to the origin
        const fromUri = authService.getFromUri();
        window.location.assign(fromUri);
      },
      (err) => {
        throw err;
      },
    );
  }, [authService, ceterusApp]);

  return (
    <div>
      <div id="sign-in-widget" />
    </div>
  );
};

export default LoginForm;