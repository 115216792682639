import React, { useState, useContext } from 'react';
import { API } from '../../../api';
import { getTheme } from '../../../constants';
import AppContext from '../../../context';
import LoadingIndicator from '../../LoadingIndicator';
import Authenticated from './Authenticated';

const inputStyle = {
  fontSize: 14,
  padding: '6px 8px',
  width: 300,
  outline: 'none',
  border: `1px grey solid`,
  borderRadius: 3,
  height: 38,
  marginTop: 5,
  boxSizing: 'border-box'
}

// Switch to this login form by setting enable-ceterus-based-login to true in the config table

const CeterusBasedLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ceterusResponse, setCeterusResponse] = useState(null);

  const { ceterusApp } = useContext(AppContext);
  const { logo } = getTheme(ceterusApp);

  const login = () => {
    setLoading(true);
    setError(null);
    setCeterusResponse(null);
    const details = { email, password };

    const formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    const body = formBody.join("&");

    fetch(`${API}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body
    })
      .then((r) => r.json())
      .then((json) => setCeterusResponse(json))
      .catch(() => setError("There was an error logging you in. Please check your username and password and try again."))
      .finally(() => setLoading(false))
  }

  if (loading) return <LoadingIndicator />
  if (ceterusResponse) return <Authenticated data={ceterusResponse} />

  return (
    <>
      {error && <div className="error panel">{error}</div>}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='ceterusLoginPanel'>
          <img src={logo} alt="Logo" style={{ maxHeight: 40, maxWidth: 200 }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <div>
              <div style={{ width: '100%', textAlign: 'left', fontSize: 14 }}>Email</div>
              <input
                style={inputStyle}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                name="username"
              />
            </div>
            <div>
              <div style={{ width: '100%', textAlign: 'left', fontSize: 14 }}>Password</div>
              <input
                type="password"
                style={inputStyle}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
                name="password"
              />
            </div>
          </div>
          <button
            className='button-primary'
            style={{
              color: 'white',
              height: 50,
              width: 316,
              cursor: 'pointer'
            }}
            onClick={login}
            disabled={loading}
          >
            Sign In
          </button>
        </div>
      </div>
    </>
  )
}

export default CeterusBasedLogin;