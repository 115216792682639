// Get any URL parameter
function getParameter(parameterToGet) {
  if (window.location.search.includes(parameterToGet + '=')) {
    let param = window.location.search.split(parameterToGet + '=')[1];
    param = param.split('&')[0];
    return decodeURIComponent(param)
  }
  return '';
}

export { getParameter }