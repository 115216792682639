import * as config from '../api';
import async from './async';

const authorizeUser = (oktaToken) => {
  const url = `${config.API}authorize`;
  return async.get(url, {}, oktaToken).then((res) => {
    if (res.ok) return res.json();
    return { data: res, error: true };
  });
};

export default {
  authorizeUser
};
