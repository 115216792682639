import React, { useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AppContext from '../../../context';
import LoginForm from './LoginForm'
import Authenticated from './Authenticated';

// This page will render the login form and redirect the user to the protected component
const Login = () => {
  const { authState } = useOktaAuth();
  const { error } = useContext(AppContext);
  return (
    <>
      {/* Show login form if they aren't logged in */}
      {authState.isAuthenticated === false &&
        <>
          {error && <div className="error panel">{error}</div>}
          <LoginForm />
        </>
      }

      {/* No need to redirect, just render the authenticated route inline if they navigate to /login while logged in */}
      {authState.isAuthenticated && <Authenticated />}
    </>
  );
}

export default Login;